import { singleton } from "@solvari/common-fe/helpers";
import { createLogger, createStore } from "vuex";

import wallet from "@/vue/apps/customer/store/modules/wallet";
import { getEnv } from "@/vue/utils/solvariConfig.ts";

import filterSettings from "./modules/filter-settings/index.js";
import workplaceFilter from "./modules/workplace-filter/index.js";
import workplace from "./modules/workplace/index.js";

export const store = singleton(() =>
  createStore({
    modules: {
      wallet,
      filterSettings,
      workplace,
      workplaceFilter,
    },
    strict: true,
    plugins: getEnv().config.isDev ? [createLogger()] : [],
  }),
);
