import axios from "axios";

import echo from "@/vue/apps/customer/plugins/echo.js";
import { getEnv } from "@/vue/utils/solvariConfig.ts";

export default {
  init({ commit, dispatch }, { uuid, balance }) {
    commit("init", { uuid, balance });

    echo()
      .private(`pro.${uuid}`)
      .listen(".wallet.balance_changed", () => {
        dispatch("refresh");
      });
  },
  async refresh({ commit }) {
    const url = `${
      getEnv().network.api.base
    }/v3/my/wallet?XDEBUG_SESSION_START=PHPSTORM`;

    const { data } = await axios.get(url);
    const { balance } = data.data;

    commit("setBalance", balance);
  },
};
