export default {
  settings: (state, settings) => {
    state.settings = settings;
  },
  categories: (state, categories) => {
    //
    categories.forEach((category) => {
      // Check if the category is already added
      const exists = state.categories.find((c) => c.id === category.id);
      // If the category exist, dont add it
      if (exists) {
        return;
      }
      // Add the new category
      state.categories.push(category);
    });
  },
};
