import { useI18nCustomer } from "@/vue/apps/customer/plugins/i18n";
import { useAlertStore } from "@/vue/apps/shared/store/alertsStore.ts";

import filterSettingsService from "../../../services/filterSettings.service.js";

export default {
  async fetchSettings({ commit }) {
    const res = await filterSettingsService.fetchSettings();
    commit("settings", JSON.parse(res.data).data);
  },
  async storeSettings({ commit }, settings) {
    const alertStore = useAlertStore();
    await filterSettingsService.storeSettings(settings);
    alertStore.add({
      message: useI18nCustomer().tr("customer.products.products_saved"),
      type: "success",
    });

    commit("settings", settings);
  },
  async fetchCategoriesForProducts({ commit, state }) {
    // Map to Promises
    const calls = Object.values(state.settings.products).map((product) => {
      return filterSettingsService.fetchCategoriesForProduct(
        product.product_id,
      );
    });
    // Wait for all calls to
    const results = await Promise.all(calls);

    const categories = results.map((r) => r.data).flat();

    commit("categories", categories);
  },
};
