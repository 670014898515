import type { LocaleMorpheus } from "@solvari/common-fe/helpers";

import { defineI18n } from "@solvari/common-fe/composables";
import ky from "ky";

import { getEnv } from "@/vue/utils/solvariConfig";

const useI18nCustomer = defineI18n((locale: LocaleMorpheus) => {
  return ky
    .get(
      `${
        getEnv().network.cdn.morpheus
      }/morpheus/translations/${locale}/pro_vue.json`,
    )
    .json();
});

export { useI18nCustomer };
