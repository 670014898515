import { Order } from "@/vue/apps/customer/enums/workplace.js";

export default {
  getLoading: (state) => state.loading,
  getFilters: (state, getters) => ({
    ...state.filters,
    sortBy: getters.getOrderDirection,
  }),

  getSavedFilters: (state) => state.savedFilters,
  getSavedFilterWithId: (state) => (id) =>
    state.savedFilters.find((filter) => filter.id === parseInt(id)),
  getAllowWorkPlaceSale: (state) => state.allowWorkplaceSale,
  getAllowSavingFilter: (state) =>
    state.allowSavingFilter && state.filters.zipCode !== "",
  getIsImpersonatingAdmin: (state) => state.isImpersonatingAdmin,
  getAccountManager: (state) => state.accountManager,

  getLeads: (state) => state.leads,
  getRedirectUrl: (state) => (leadId) => state.redirectUrls[leadId],
  getBuyError: (state) => state.buyError,

  /**
   * Filter
   */
  getFilterLoaded: (state) => state.filterLoaded,
  getLocale: (state) => state.filters.locale,
  getRadius: (state) => state.filters.radius ?? 50,
  getZipCode: (state) => state.filters.zipCode ?? "",
  getSlotsAvailable: (state) => state.filters.slotsAvailable,
  getContact: (state) => state.filters.contact,
  getOrderBy: (state) => state.filters.orderBy,
  getOrderDirection: (state) =>
    state.filters.orderBy === Order.DISTANCE ? "asc" : undefined,
  getProducts: (state) => state.filters.products,
  getTextSearchTags: (state) => state.filters.textSearchTags,
  getSelectedFilter: (state) => state.selectedFilter,
  getName: (state) => state.filters.name,
  getId: (state) => state.filters.id,
  getNotificationFrequency: (state) => state.filters.notificationFrequency,
  getNotificationFrequencyDay: (state) =>
    state.filters.notificationFrequencyDay,
  getNotificationFrequencyHour: (state) =>
    state.filters.notificationFrequencyHour,
  getNotificationFrequencyWeekend: (state) =>
    state.filters.notificationFrequencyWeekend,
  getIsFavorite: (state) => state.filters.isFavorite,

  /**
   * Meta
   */
  getIsFilterOpen: (state) => state.meta.isFilterOpen,
  getCurrentPage: (state) => state.meta.currentPage,
  getNoMoreLeadsAvailable: (state) =>
    state.meta.currentPage === state.meta.lastPage &&
    state.meta.lastPage !== null,
  getMoreLeadsAvailable: (state) =>
    state.meta.currentPage !== state.meta.lastPage ||
    state.meta.lastPage === null,
  getTotalLeads: (state) => state.meta.totalLeads,
};
