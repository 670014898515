import { singleton } from "@solvari/common-fe/helpers";
import axios from "axios";
import Echo from "laravel-echo";

import { ziggy } from "@/vue/apps/admin/plugins/ziggy.ts";
import { useAuthStore } from "@/vue/apps/shared/store/authStore.ts";
import { getEnv } from "@/vue/utils/solvariConfig.ts";

import "pusher-js";

export default singleton(() => {
  return new Echo({
    authEndpoint: ziggy("api.v3.broadcasting.auth"),
    broadcaster: "pusher",
    key: getEnv().integrations.pusher.key,
    cluster: getEnv().integrations.pusher.cluster,
    forceTLS: true,
    authorizer: (channel, options) => ({
      authorize: async (socketId, callback) => {
        try {
          const response = await axios.post(
            options.authEndpoint,
            { socket_id: socketId, channel_name: channel.name },
            {
              headers: {
                Authorization: `Bearer ${useAuthStore().accessToken}`,
              },
            },
          );
          callback(false, response.data);
        } catch (error) {
          callback(true, error);
        }
      },
    }),
  });
});
