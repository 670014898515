export default {
  settings: (state) => {
    return state.settings;
  },
  products: (state) => {
    return state.products;
  },
  categories: (state) => {
    return state.categories;
  },
  getCategoryById: (state) => (id) => {
    return state.categories.find((c) => c.id === id);
  },
};
