export default {
  init(state, { uuid, balance, type }) {
    state.uuid = uuid;
    state.balance = parseFloat(balance);
    state.type = type;
  },
  setBalance(state, balance) {
    state.balance = parseFloat(balance);
  },
};
