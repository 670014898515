export const SlotsAvailable = Object.freeze({
  0: 0,
  1: 1,
  2: 2,
  3: 3,
});

export const Contact = Object.freeze({
  SPOKEN: "spoken",
});

export const Order = Object.freeze({
  DISTANCE: "distance",
  SPACE: "space",
  NEWEST: "created_at",
});
